<template>
  <div
      :class="`${backgroundColor} ${textColor}--text d-flex align-center`"
      :style="{height: '60px'}"
  >
    <v-row align="center" justify="center" no-gutters>
      <h1 v-if="$vuetify.breakpoint.mdAndUp" class="text-center">
        {{ text }}
      </h1>
      <h3 v-else class="text-center">
        {{ text }}
      </h3>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'titulosFondos',
  props: {
    text: {
      type: String,
      required: true,
    },
    backgroundColor: {
      type: String,
      default: 'info',
    },
    textColor: {
      type: String,
      default: 'white',
    },
  },
};
</script>
