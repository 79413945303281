var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-text-field',_vm._g(_vm._b({ref:"textField",class:_vm.classDefined,attrs:{"color":_vm.textColor,"error-messages":_vm.message,"error":_vm.error,"outlined":_vm.outlined || _vm.isOutline,"rules":_vm.required
      ? _vm.isCorreo
        ? _vm.emailRules
        : _vm.isRenta
        ? _vm.rentaRules
        : _vm.newRules && _vm.newRules.length
        ? _vm.newRules
        : _vm.basicRule
      : _vm.newRules && _vm.newRules.length
      ? _vm.newRules
      : [],"placeholder":_vm.placeholder,"background-color":_vm.backgroundColor,"required":_vm.required,"type":_vm.type,"value":_vm.value},on:{"click":function($event){_vm.outlined = true}}},'v-text-field',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }