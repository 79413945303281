<template>
  <v-text-field
    v-bind="$attrs"
    :color="textColor"
    :error-messages="message"
    ref="textField"
    :error="error"
    :outlined="outlined || isOutline"
    :rules="
      required
        ? isCorreo
          ? emailRules
          : isRenta
          ? rentaRules
          : newRules && newRules.length
          ? newRules
          : basicRule
        : newRules && newRules.length
        ? newRules
        : []
    "
    :placeholder="placeholder"
    :background-color="backgroundColor"
    :required="required"
    :type="type"
    :value="value"
    :class="classDefined"
    @click="outlined = true"
    v-on="$listeners"
  ></v-text-field>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: 'textDinamico',
  props: {
    value: {
      type: String,
    },
    placeholder: {
      type: String,
      required: false,
    },
    backgroundColor: {
      type: String,
      default: 'white',
    },
    message: {
      type: String,
      default: '',
    },
    textColor: {
      type: String,
      default: 'info',
    },
    required: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: '',
    },
    classDefined: {
      type: String,
      default: '',
    },
    isCorreo: {
      type: Boolean,
      default: false,
    },
    isRenta: {
      type: Boolean,
      default: false,
    },
    isOutline: {
      type: Boolean,
      default: false,
    },
    newRules: {
      type: Array,
      default: () => {
        [];
      },
    },
  },
  computed: {
    ...mapState(['rules']),
  },
  data: () => ({
    error: false,
    outlined: false,
    clickTextField: false,
    basicRule: [(v) => !!v || 'Campo Requerido'],
    emailRules: [
      (v) => !!v || 'E-mail es requerido',
      (v) =>
        /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(v) ||
        'Debe ser un E-mail valido',
    ],
    rentaRules: [
      (v) => !!v || 'Renta es Requerida',
      (v) => v > 549999 || 'Renta Minima $550.000',
    ],
  }),
  mounted() {
    this.setBorderColor();
  },
  methods: {
    setBorderColor() {
      const element = this.$refs.textField.$el.querySelector('fieldset');
      const textComponent = this.$refs.textField;

      if (!element) return null;

      element.style.borderColor = 'black';

      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.attributeName === 'class') {
            const isError = Boolean(textComponent.validations.length);

            if (this.isDisabled) {
              element.style.borderColor = '#e3e2e2';
            } else if (this.error || isError) {
              element.style.borderColor = 'red';
            } else {
              element.style.borderColor = '#0032a5';
            }
          }
        });
      });
      observer.observe(element.closest('.v-input'), {attributes: true});
    },
    validate() {
      if (this.$refs.textField) {
        return this.$refs.textField.validate();
      }
    },
  },
};
</script>

<style scoped>
.sinFlechas input[type='number'] {
  -moz-appearance: textfield;
}

.sinFlechas input::-webkit-outer-spin-button,
.sinFlechas input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.v-text-field input {
  color: white;
}
</style>
