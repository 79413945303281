<template>
  <div style="width: 100%">
    <titulos-fondos text="¡Hola! Nos gustaría saber tu opinión"/>
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12">
        <v-row align="center" justify="center" no-gutters>
          <h2 class="title--text align-center my-6">¡Gracias por elegir a Petersen! </h2>
        </v-row>
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="7" sm="10">
        <p class="title--text text-center">Nos encanta tener clientes
          satisfechos, y
          para asegurarnos de
          que estamos brindando el mejor servicio posible, nos gustaría pedirte que completes nuestra encuesta de
          satisfacción.</p>
      </v-col>
    </v-row>
    <v-container class="justify-center">
      <v-row align="center" justify="center" no-gutters>
        <v-col cols="12" xl="6" lg="8" md="10" sm="10">
          <v-row align="start" justify="start" no-gutters>
            <v-card v-if="executive && executive.photo && $vuetify.breakpoint.smAndUp">
              <v-row align="center" justify="space-between" no-gutters class="py-2">
                <v-col cols="8">
                  <v-card-title class="title--text font-weight-bold">{{ executive.name }}</v-card-title>
                  <v-card-subtitle class="title--text">Ejecutivo Comercial</v-card-subtitle>
                  <v-card-subtitle class="title--text py-2">Califique cómo le ha ayudado, qué le ha gustado y qué
                    podría
                    mejorar.
                    Su feedback es importante para nosotros.
                  </v-card-subtitle>
                </v-col>
                <v-col cols="4">
                  <v-row justify="end" no-gutters class="mr-7">
                    <v-avatar size="130" style="border: 2px solid #0434AC;">
                      <v-img
                          alt="Ejecutivo"
                          height="250"
                          :src="executive.photo"
                      ></v-img>
                    </v-avatar>
                  </v-row>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-card-actions style="background-color: #0434AC">
                <v-row align="center" justify="space-between" no-gutters>
                  <v-col cols="7" sm="6">
                      <span class="white--text font-weight-bold ml-2">
                        Califica la atención del ejecutivo
                        </span>
                  </v-col>
                  <v-col cols="5" sm="6">
                    <v-row align="center" justify="end" no-gutters class="pr-4">
                      <span class="white--text">({{ form.rating || 0 }})</span>
                      <v-rating v-model="form.rating" color="yellow" :disabled="disabledSend"
                                background-color="yellow"
                                length="5"/>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
            <v-card v-else-if="executive && executive.photo && $vuetify.breakpoint.xsOnly">
              <v-row align="center" justify="center" no-gutters class="pt-2">
                <v-col cols="12">
                  <v-row justify="center" no-gutters>
                    <v-avatar size="130" style="border: 4px solid #0434AC;">
                      <v-img
                          alt="Ejecutivo"
                          height="250"
                          :src="executive.photo"
                      ></v-img>
                    </v-avatar>
                  </v-row>
                </v-col>
                <v-col cols="12" class="pt-2">
                  <v-card-title class="title--text font-weight-bold justify-center">{{
                      executive.name
                    }}
                  </v-card-title>
                  <v-card-subtitle class="title--text text-center">Ejecutivo Comercial</v-card-subtitle>
                  <v-card-subtitle class="title--text py-2 text-center">Califique cómo le ha ayudado, qué le ha gustado
                    y qué
                    podría
                    mejorar.
                    Su feedback es importante para nosotros.
                  </v-card-subtitle>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-card-actions style="background-color: #0434AC">
                <v-row align="center" justify="space-between" no-gutters>
                  <v-col cols="12">
                    <p class="white--text font-weight-bold text-center mb-0">
                      Califica la atención del ejecutivo
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <v-row align="center" justify="center" no-gutters>
                      <span class="white--text">({{ form.rating || 0 }})</span>
                      <v-rating v-model="form.rating" color="yellow" :disabled="disabledSend"
                                background-color="yellow"
                                large
                                length="5"/>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-row>
          <v-card class="my-6">
            <v-container>
              <h4 class="title--text pa-4">¿Cómo nos conociste?</h4>
              <auto-completar v-model="form.channel" :items="channel" item-value="id" item-text="name"
                              :disabled="disabledSend"
                              placeholder="Selecciona una opción"/>
              <h4 class="title--text pa-4">¿Recomendarías Automotriz Petersen a tus amigos?</h4>
              <v-col cols="12">
                <v-radio-group
                    v-model="form.recommend"
                    row
                    :disabled="disabledSend"
                >
                  <v-radio
                      color="title"
                      label="Si"
                      :value="true"
                  ></v-radio>
                  <v-radio
                      color="title"
                      label="No"
                      :value="false"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <h4 class="title--text pa-4">¿Sentiste que obtuviste lo que querías?</h4>
              <v-col cols="12">
                <v-radio-group
                    v-model="form.feeling"
                    row
                    :disabled="disabledSend"
                >
                  <v-radio
                      color="title"
                      label="Si"
                      :value="true"
                  ></v-radio>
                  <v-radio
                      color="title"
                      label="No"
                      :value="false"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <h4 class="title--text pa-4">¿Volverías a vender / comprar tu auto con nosotros?</h4>
              <v-col cols="12">
                <v-radio-group
                    v-model="form.again"
                    row
                    :disabled="disabledSend"
                >

                  <v-radio
                      color="title"
                      label="Si"
                      :value="true"
                  ></v-radio>
                  <v-radio
                      color="title"
                      label="No"
                      :value="false"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <h4 class="title--text pa-4">Si quieres darnos alguna recomendación, te leemos:</h4>
              <text-dinamico v-model="form.comment" :disabled="disabledSend" placeholder="Recomendación"
                             :required="false" is-outline/>
              <v-col cols="12" class="mb-2">
                <v-row align="center" :justify="$vuetify.breakpoint.xsOnly?'center':'space-between'" no-gutters>
                  <h3 class="title--text mb-3">¡Muchas gracias por tu tiempo!</h3>
                  <v-btn color="title" class="white--text" :disabled="disabledSend" @click="send">Enviar</v-btn>
                </v-row>
              </v-col>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar" color="info" centered>
        <v-row align="center" justify="center" no-gutters>
          {{ message }}
        </v-row>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import TextDinamico from "@/components/textDinamico.vue";
import TitulosFondos from "@/components/titulosFondos.vue";
import AutoCompletar from "@/components/core/autoCompletar.vue";

export default {
  name: "FeedBack",
  components: {AutoCompletar, TitulosFondos, TextDinamico},
  data: () => ({
    snackbar: false,
    message: '',
    form: {
      id: null,
      channel: '',
      feeling: true,
      rating: null,
      recommend: true,
      again: true,
      comment: ''
    },
  }),
  methods: {
    ...mapActions(['FIND_EXECUTIVE', 'FIND_CHANNEL', 'SEND_EVALUATION']),
    ...mapMutations(['SET_EVALUATION_SEND']),
    send() {
      const {form} = this

      if (form.rating === null) {
        this.message = 'Por Favor califique al ejecutivo'
        this.snackbar = true
      }
      if (!form.channel) {
        this.message = 'Canal es requerido por favor selecciona una opcion'
        this.snackbar = true
      }

      this.SEND_EVALUATION(form)
    }
  },
  computed: {
    ...mapState(['channel', 'executive', 'evaluationSend', 'calificationDone']),
    disabledSend() {
      const {snackbar, calificationDone, message} = this

      return snackbar && calificationDone && message === 'La evaluación Ya fue enviada'
    }
  },
  watch: {
    evaluationSend: {
      immediate: true,
      handler(val) {
        if (val) {
          this.message = 'Gracias Evaluación enviada'
          this.snackbar = true
        }
      }
    },
    calificationDone: {
      immediate: true,
      handler(val) {
        if (val) {
          this.message = 'La evaluación Ya fue enviada'
          this.snackbar = true
        }
      }
    },
    snackbar: {
      immediate: true,
      handler(val) {
        if (!val && this.calificationDone && this.message === 'La evaluación Ya fue enviada') {
          this.$router.push({name: 'petersen'})
        }
      }
    }
  },
  mounted() {
    const {
      $route: {
        query: {id},
      },
    } = this

    if (!id) this.$router.push({name: 'petersen'})
    this.SET_EVALUATION_SEND(false)
    this.FIND_CHANNEL()
    this.FIND_EXECUTIVE(id);
    this.form.id = id
  },
}
</script>

<style scoped>

</style>